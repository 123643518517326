<template>
  <div class="d-flex-centered">
    <toko-search-empty-icon />
    <p class="text-uppercase text-bold mt-24">NO RESULT FOUND</p>
    <p class="text-center mt-8">
      We cannot find any results matching with <br />
      your search, maybe a little spelling mistake?
    </p>
  </div>
</template>

<script>
import TokoSearchEmptyIcon from '@/ui/toko-icons/TokoSearchEmpty'

export default {
  name: 'NoResult',

  components: {
    TokoSearchEmptyIcon
  }
}
</script>
